var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-row",
        { staticClass: "align-center", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card-title",
                { staticClass: "flex-nowrap" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "text-h6 text-decoration-none secondary--text mr-3",
                      attrs: {
                        to: {
                          name: "snapshot-overview",
                          params: _vm.routeParams(),
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.item.space_long_id))])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.item.space_description
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-card-subtitle", { staticClass: "pt-0" }, [
                    _vm._v(" " + _vm._s(_vm.item.space_description) + " "),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        elevation: "0",
                        to: {
                          name: "snapshot-files",
                          params: _vm.routeParams("file"),
                        },
                        small: "",
                        fab: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("folder"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: {
                        elevation: "0",
                        to: {
                          name: "snapshot-tables",
                          params: _vm.routeParams(),
                          query: _vm.$route.query,
                        },
                        small: "",
                        fab: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("mdi-table-large"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        elevation: "0",
                        to: {
                          name: "snapshot-applications",
                          params: _vm.routeParams(),
                          query: _vm.$route.query,
                        },
                        small: "",
                        fab: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "secondary" } }, [
                        _vm._v("desktop_windows"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }